import React from "react";
import { SortFilterComboBoxItem } from "./SortFilterComboBoxItem";

/*Import de media (img)*/
import { ReactComponent as DropDownArrow } from "../ui/img/drop-down-arrow.svg";

//Import de Material UI
import Tooltip from '@material-ui/core/Tooltip';

// Descripción: ComboBox padre que tendrá unos items o valores gestionados con SortfilterComboBoxItem
// Params: Se utiliza el fichero types.js para guardar referencia de nombres
//comboTitle: El título o la label que estará encima del comboBox
//sortFilter: El nombre que tendrá la casilla donde estarán las opciones de filtrado para interacturar con jquery (sortFilter__carburante, sortFilter__price)
//panel_data: El nombre del panel que contiene los items. Necesario para interacturar con jquery (Ocultar, Mostrar) 
//items será un array de items que formarán el comboBox (Cada una de las opciones seleccionables)

export const SortFilterComboBox = ({ comboTitle, sortFilter, panel_data, default_field_name,items, activeOption, toolTipMessage }) => {

// Hooks

return (
    <>
      <fieldset>
        <label>{comboTitle}</label>
        
      
    
        <span
          className={`btn-dropdown sortFilter ${sortFilter}`}
          panel-data={panel_data}
        >
          <Tooltip title={toolTipMessage} arrow>
          <span
            className="form-btn outlined btn-dropdown-link"
          >
            <DropDownArrow className="btn-dropdown-caret" />
            {/* Aquí irá el título o value seleccionado. Cambia según el subitem pulsado */}
            <span data-option="" data-value="" className={default_field_name}>{activeOption}</span>
          </span>
          </Tooltip>
          <div
            className="btn-dropdown-options sets-querystring"
            id={panel_data}
          >
            <ul>
              {/* Cada una de las opciones que forman el comboBox */}              
              {items.map( (item) =>               
                (
                  <SortFilterComboBoxItem key={item.data_option} item={item}/>
                )
              )
              }
            </ul>
          </div>
        </span>
      </fieldset>
    </>
  );
};
