import React from 'react'

//Bloque donde irá un array de items mostrando un título, una pequeña descripción y la imagen
export const ContentTitleSubtitleWithImageItem = ({item}) => {
    return (
        <div className="contentBlockTitleSubtitleWithImageItem__container">
            <h3>{item.title}</h3>
            <p>{item.subtitle}</p>
            {<img src= {item.image} alt={item.imageTitle} title={item.imageTitle}/> }
        </div>
    )
}
