import React from "react";

/*Import de botón "Descargar" (img)*/
import { ReactComponent as DownloadAppButton } from "../img/download-button-white-icon.svg";
import { useSelector } from "react-redux";

export const Footer = () => {

  // Hooks
  //------
  // Cargar la fecha de última comprobación desde Redux
  const {lastDateCheckPrice} = useSelector(state => state.ui);


  return (
    <footer className="footer__container">
      <div className="footer__items_container">
        <div className="footer_block">
          <div className="footer__item left">
            <i className="fa fa-cloud-download-alt icon fa-2x"></i>
            <div className="footer__item_content">
              <h5>Última comprobación de precios</h5>
              <p>{lastDateCheckPrice}</p>
            </div>
          </div>

          <div className="footer__item left">
            <i className="fa fa-mobile-alt icon fa-2x"></i>
            <div className="footer__item_content">
              <a
                href="https://apps.apple.com/es/app/gasonline/id1502291107"
                title="Descargar GasOnline gratis"
              >
                <h5>Descargar GasOnline</h5>
                <p>Disponible para iOS</p>
              </a>
            </div>
          </div>
        </div>

        <div className="footer_block">
          <div className="footer__item center">
            <a
              href="https://apps.apple.com/es/app/gasonline/id1502291107"
              title="Descargar GasOnline gratis"
            >
              <DownloadAppButton
                title=""
                title="Descargar GasOnline gratis"
                alt="Descargar GasOnline gratis"
              />
            </a>
          </div>
        </div>

        <div className="footer_block">
          <div className="footer__item right">
            <i className="fa fa-user icon fa-2x secondElementOrder"></i>
            <div className="footer__item_content fistElementOrder">
              <a
                href="https://www.linkedin.com/in/enrique-alonso-delgado-3a89b4b5/"
                title="Contacta conmigo vía Linkedin"
              >
                <h5>Diseño y Desarrollo</h5>
                <p>Enrique Alonso Delgado</p>
              </a>
            </div>
          </div>

          <div className="footer__item right">
            <i className="fa fa-envelope icon fa-2x secondElementOrder"></i>

            <div className="footer__item_content fistElementOrder">
              <a href="mailto:info@gasonlineapp.es" title="Contactar vía email">
                <h5>Contacto</h5>
                <p>info@gasOnlineapp.es</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
