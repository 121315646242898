// Tipos de acciones: Identificador  - Valor

export const types = {
  // Acciones de ui
  //---------------
  // Para mostrar el loading cuando se busque una localidad
  uiDisplayLoadingLocation: "[ui] uiDisplayLoadingLocation",
  // Para ocultar el loading cuando se busque una localidad
  uiNotDisplayLoadingLocation: "[ui] uiNotDisplayLoadingLocation",

  // Para mostrar la lista de resultados de localidades encontradas
  uiShowLoadingListBox: "[ui] uiShowLoadingListBox",
  // Para ocultar la lista de resultados de localidades encontradas
  uiNotShowLoadingListBox: "[ui] uiNotShowLoadingListBox",

  // Para ocultar o mostrar el "Cargando precios"
  uiDisplayLoadingPrices: "[ui] uiDisplayLoadingPrices",
  uiNotDisplayLoadingPrices: "[ui] uiNotDisplayLoadingPrices",

  // Para establecer la fecha de última comprobación de precios
  uiAddLastDateCheckPrice: "[ui] uiAddLastDateCheckPrice",

  // Acciones de municipio 
  //---------------------
  // Cargar los municipios encontrados en el store de redux tras haber realizado la petición
  locationMunicipioAddFoundItems: "[locationMunicipio] locationMunicipioAddFoundItems",
  // Eliminar los municipios encontrados en el store de redux. Resetear la búsqueda.
  locationMunicipioRemoveFounditems: "[locationMunicipio] locationMunicipioRemoveFounditems",

  // Establecer la location seleccionada por el usuario (Al hacer clic en la ubicación deseada)
  locationMunicipioSetActive: "[locationMunicipio] locationMunicipioSetActive",

  // Acciones de provincias
  //---------------------
  // Cargar las provincias encontradas en el store de redux tras haber realizado la petición
  locationProvinciaAddFoundItems: "[locationProvincia] locationProvinciaAddFoundItems",
  // Eliminar los municipios encontrados en el store de redux. Resetear la búsqueda.
  locationProvinciaRemoveFounditems: "[locationProvincia] locationProvinciaRemoveFounditems",

  // Establecer la location seleccionada por el usuario (Al hacer clic en la ubicación deseada)
  locationProvinciaSetActive: "[locationProvincia] locationProvinciaSetActive",


  // Acciones de location (Tendrá municipios y provincias) 
  //---------------------
  // Cargar los municipios y provincias encontrados en el store de redux tras haber realizado la petición
  locationAddFoundItems: "[location] locationAddFoundItems",
  // Eliminar los municipios y provincias encontrados en el store de redux. Resetear la búsqueda.
  locationRemoveFoundItems: "[location] locationRemoveFoundItems",  

  // Establecer la location seleccionada por el usuario (Al hacer clic en la ubicación deseada)
  locationSetActive: "[location] locationSetActive",

  // Acciones de estacion
  //---------------------
  // Cargar las estaciones encontradas en el store de redux tras haber realizado la petición
  estacionAddFoundItems: "[estacion] estacionAddFoundItems",

  // Acciones de sort
  //-----------------
  // Añadir/Modificar la ordenación por "Carburante"
  addSortCarburanteOption: "[sort] addSortCarburanteOption",
  // Añadir/Modificar la ordenación por "Precio"
  addSortPrecioOption: "[sort] addSortPrecioOption",
};
