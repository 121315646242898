import React from "react";
import { useDispatch, } from "react-redux";
import {
  addSortCarburanteOption,
  addSortPrecioOption,
  sortStationList,
} from "../../actions/sort";
import { sortFilterTypes } from "./sortFilterTypes";

//Descripción: Cada uno de los items que tendrá un comboBox
export const SortFilterComboBoxItem = ({ item }) => {
  // Hooks
  //------
  const dispatch = useDispatch();

  const handleSetSortSelected = () => {
    switch (item.data_param) {
      case sortFilterTypes.sortFilter.carburanteDefaultFieldName:
        // El ComboItem es de Carburante (ayudado por los sortFilterTypes (key:value))
        // Actualizo la ordenación por carburante
        dispatch(addSortCarburanteOption(item));
        break;

      case sortFilterTypes.sortFilter.precioDefaultFieldName:
        // El ComboItem es de Precio (ayudado por los sortFilterTypes (key:value))
        // Actualizo la ordenación por precio
        dispatch(addSortPrecioOption(item));
        break;

      default:
        break;
    }

    // Ordenar los items de Redux
    dispatch( sortStationList() );
    
  };

  return (
    <li
      data-value={item.data_value}
      data-option={item.data_option}
      data-param={item.data_param}
      className="sort-option"
      onClick={handleSetSortSelected}
    >
      {item.icono.render()}{" "}
      <span data-option={item.data_option}>{item.data_value}</span>
    </li>
  );
};
