import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "../../hooks/useForm";
import { LocationSearcherBox } from "./LocationSearcherBox";

// UIActions
import { uiDisplayLoadingLocation, uiShowLoadingListBox } from "../../actions/ui";
import { municipiosRemoveFoundItems } from "../../actions/locationMunicipio";

/*Import de media (img)*/
import { ReactComponent as LoadingIcon } from "../ui/img/loading-icon-small.svg";
import { startFindMunicipioByName } from "../../actions/locationMunicipio";
import { provinciasRemoveFoundItems, startFindProvinciaByName } from "../../actions/locationProvincia";

//Descripción: Buscador de localidad. Consta de un input y un botón para realizar la búsqueda de la localidad
export const LocationSearcher = () => {
  
  // Hooks
  //---------
  // Caja de búsqueda
  const [values, handleInputChange] = useForm({ searchLocation: "" });
  const { searchLocation } = values;

  // Referencia del input
  const inputSearchRef = useRef();

  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.ui);

  // Constantes
  //--------------
  // Nº de caractereres mínimo para hacer búsqueda de localidad
  const searchLimitCharacters = 3;

  // Métodos
  //--------------
  // Función que se ejecutará para iniciar la búsqueda de una localidad introducida por el usuario
  // Los resultados se mostrarán en "LocationSearcherBox"
  const handleFindLocation = (event) => {
    event.preventDefault();
  };

  // searchLocation: Se ejecuta para hacer petición al servidor cuando cambia su valor
  useEffect(() => {
    // Comprobar que el campo tiene una longitud mínima para hacer petición al servidor
    const isLocationFieldValid = () => {
      if (searchLocation.trim().length < searchLimitCharacters) {
        // En caso de que se hayan introducido municipios y se haya vuelto a atrás (eliminar el input)
        dispatch(uiShowLoadingListBox (false));
        return false;
        
      } else {
        return true;
      }
    };
    // Ejecutar la búsqueda si se cumple la longitud de la cadena a buscar
    if (isLocationFieldValid() === true) {
      dispatch( startFindMunicipioByName(searchLocation) );
      dispatch( startFindProvinciaByName(searchLocation) );
    }else{            
      // Reseteamos por si acaso los items encontrados ya hará busqueda nueva al eliminar todo el cuadro de búsqueda
      dispatch( municipiosRemoveFoundItems());
      dispatch( provinciasRemoveFoundItems());
    } 
  }, [searchLocation, dispatch]);

  return (
    <div className="locationSearcher__container">
      <div className="locationSearcher__container_header">
        <h2>Localiza el carburante más barato de España</h2>
      </div>
      
      <form onSubmit={handleFindLocation}>
        <div className="locationSearcher__container_input">
          <input
            ref={inputSearchRef}
            className="form-control"
            type="text"
            name="searchLocation"
            placeholder="Introduce un municipio o provincia"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {/* <i className="fa fa-search fa-lg fa-fw" aria-hidden="true"></i> */}
          <i className="fa fa-map-marker-alt fa-lg fa-fw" aria-hidden="true"></i>
          

          <LoadingIcon
            className={`locationSearcher__loading-icon ${
              uiState.loadingLocation ? "visible" : "not-visible"
            } `}
            title="Cargando..."
            alt="Cargando ..."
          />
        </div>
      </form>
      <LocationSearcherBox inputSearch={inputSearchRef} />
    </div>
  );
};
