import React from "react";
import ReactDOM from "react-dom";

import { GasonlineApp } from "./GasonlineApp";
// Importado fichero styles de sass.
import "./styles/styles.scss";


ReactDOM.render(
  <GasonlineApp />,
  document.getElementById("root")
);