import { sortFilterTypes } from "../components/SortFilter/sortFilterTypes";
import { types } from "../types/types";
import { estacionAddFoundItems } from "./estacion";

// Establecer en Redux la ordenación por carburante
export const addSortCarburanteOption = (item) => {
  return {
    type: types.addSortCarburanteOption,
    payload: item,
  };
};

// Establecer en Redux la ordenación por precio
export const addSortPrecioOption = (item) => {
  return {
    type: types.addSortPrecioOption,
    payload: item,
  };
};

// Establecer en Redux el orden de estaciones según el las opciones de carburante y precio de Redux.
export const sortStationList = () => {
  try {
    return async (dispatch, getState) => {
      // 1- Estaciones con precio de Redux para ordenar
      const stationList = getState().estaciones.stationList;
      
      // 2- Ordenar precio siempre y cuando haya más de 2 gasolineras
      if (stationList.length > 1) {                
        // 2- Campo de ordenación de combustible
        const sortCombustible = getState().sortBy.combustible
          .database_field_name;
        // 3- Campo de ordenación del precio
        const sortPrecio = getState().sortBy.precio.data_option;

        // 4- Proceder a ordenar : Ascendente o Descendente?
        if (
          sortPrecio === sortFilterTypes.precio.precioAscendente.data_option
        ) {
          // 4.1 - Ordenar precio de forma ascendente (de menor a mayhor)
          stationList.sort((a, b) => a[sortCombustible] - b[sortCombustible]);
        } else {
          // 4.2 - Ordenar precio de forma descendente (de mayor a menor)
          stationList.sort((a, b) => b[sortCombustible] - a[sortCombustible]);
        }

      // 5- Cargar los resultados en el Store de estaciones
      dispatch(estacionAddFoundItems(stationList));
      }
    };
  } catch (error) {
    console.log(error);
  }
};
