import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ListPriceItemEmpty } from "./ListPriceItemEmpty";
import { LoadingWithMessage } from "../ui/LoadingWithMessage";
import { ListPriceItemList } from "./ListPriceItemList";
import { SortFilterData } from "../SortFilter/SortFilterData";

//Importar componentes de Material UI de google
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

//Descripción: Contenedor de la lista de gasolineras con sus respectivos precios.
export const ListPrice = () => {
  //Hooks
  //-------
  // Panel que se mostrará por defecto (Buscar municipio)
  const { locations, estaciones, ui } = useSelector((state) => state);

  // Controlar si se están comprobando/descargando precios
  const loadingPrices = ui.loadingPrices;
  const locationSelected = locations.locationSelected;

  //Constantes
  //----------
  // Controlar mediante un enum el panel informativo a mostrar (Precios, Encuentra la gasolinera, Cargando ...)
  const panels = {
    SET_LOCATION: (
      <ListPriceItemEmpty
        title="Encuentra la gasolinera más barata ..."
        description=" Localiza con el buscador el mejor precio del carburante para tu vehículo"
      />
    ),
    LOADING: <LoadingWithMessage message="Cargando precios ..." />,
    DISPLAY_PRICES: <ListPriceItemList />,
  };

  // Panel a mostrar dependiendo del cambio del estado
  const [panel, setPanel] = useState(panels.SET_LOCATION);
  const [title, setTitle] = useState("Buscar municipio o provincia");

  useEffect(() => {
    // COMPROBAR LOS ESTADOS DEL STATE PARA MOSTRAR LOADING O NO
    if (loadingPrices === true) {
      //console.log("Mostrar la carga de precios");
      setPanel(panels.LOADING);
      setTitle("Cargando precios ...");
    } else if (locationSelected.location !== null && loadingPrices === false) {
      // Sacar municipio o provincia para el título
      let locationTitle = "";
      locationSelected.isLocationSelectedProvincia
        ? (locationTitle =
            " la provincia de " + locationSelected.location.Provincia)
        : (locationTitle = locationSelected.location.Municipio);

      // Comprobamos si hay resultados de estaciones y cambiamos el título a mostrar
      if (estaciones.stationList.length > 1) {
        setTitle(
          `${estaciones.stationList.length} gasolineras encontradas en ${locationTitle}`
        );
      } else {
        setTitle(
          `${estaciones.stationList.length} gasolinera encontrada en ${locationTitle}`
        );
      }
      setPanel(panels.DISPLAY_PRICES);
    } else {
      setTitle("Buscar municipio o provincia");
      setPanel(panels.SET_LOCATION);
    }
  }, [loadingPrices, locationSelected, estaciones]);

  return (
    <div className="listPrice__container">
      <div className="listPrice__header">
        <p>
          <i className="fa fa-list fa-fw" aria-hidden="true"></i>
          {title}
        </p>

        {/* <div className="listPrice__sort_button">
          <span className="listPrice__sort_button_title">
            <i className="fa fa-filter fa-fw" aria-hidden="true"></i>Filtros
          </span>
        </div> */}

        <Tooltip title="Mostrar / Ocultar filtros" arrow>
          <Button
            variant="contained"
            color="default"
            className="listPrice__sort_button"
          >
            <i className="fa fa-filter fa-fw" aria-hidden="true"></i>Filtros
          </Button>
        </Tooltip>
      </div>

      <div variant="text" className="listPrice__sortFilter_container">
        {
          // Filtros disponibles en la web (Array)
          SortFilterData.map((filter) => filter)
        }
      </div>

      <div className="listPrice__body">{panel}</div>
    </div>
  );
};
