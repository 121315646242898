/*
Guardar el carburante y el tipo de ordenación en el que se desea obtener las estaciones de servicio
*/

import { sortFilterTypes } from "../components/SortFilter/sortFilterTypes";
import { types } from "../types/types";

const initialValues = {
  combustible: sortFilterTypes.combustible.gasolina95,
  precio: sortFilterTypes.precio.precioAscendente,
};

export const sortReducer = (state = initialValues, action) => {
  switch (action.type) {
    // Cambiar la ordenación por carburante (Cambiado desde ComboBoxItem)
    case types.addSortCarburanteOption:
      return { ...state, combustible: action.payload };

    // Cambiar la ordenación por precio (Cambiado desde ComboBoxItem)
    case types.addSortPrecioOption:
      return { ...state, precio: action.payload };
    default:
      return state;
  }
};