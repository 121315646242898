import React from "react";
import { Link, NavLink } from "react-router-dom";

/*Import de media (img)*/
import { ReactComponent as GasOnlineLogo } from "./img/gasOnline-icon.svg";

export const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar__app-logo-title-container">
        <Link to="/">
          <GasOnlineLogo
            className="navbar__app-logo"
            title="Gasonline: Los precios de los carburantes online"
            alt="Gasonline: Los precios de los carburantes online"
          />
        </Link>

        <div className="navbar__app-title-container">
          <p className="navbar__app-title">GasOnline</p>
          <p className="navbar__app-subtitle">
            Precio de los carburantes online
          </p>
        </div>
      </div>

      <div className="topnav" id="myTopnav">
        <NavLink exact activeClassName="active" to="/" title="Bienvenid@ a GasOnline">
          Inicio
        </NavLink>
        <NavLink exact activeClassName="active" to="/politica-de-privacidad" title="Política de privacidad de GasOnline">
          Política de privacidad
        </NavLink>
        {/* <NavLink exact activeClassName="active" to="/condiciones-de-uso" title="Condiciones de uso de GasOnline">
          Condiciones de uso
        </NavLink> */}
        <a className="icon" id="responsiveMenuButton">
          <i className="fa fa-bars"></i>
        </a>
      </div>
    </nav>
  );
};
