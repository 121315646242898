/*
Guardar los municipios encontradas (Array) y la localidad seleccionada por el usuario.
*/

import { types } from "../types/types";

const initialValues = {
  municipios: [],
};

export const municipioReducer = (state = initialValues, action) => {
  switch (action.type) {
    // Añadir municipios encontrados al Store
    case types.locationMunicipioAddFoundItems:
      return { ...state, municipios: action.payload };

    // Eliminar municipios encontrados del Store. (Nueva posible búsqueda)
    case types.locationMunicipioRemoveFounditems:
      return { ...state, municipios: [] };
      
    default:
      return state;
  }
};
