import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowLoadingListBox } from "../../actions/ui";
import { locationAddFoundItems, locationsRemoveFoundItems } from "../../actions/location";
import { createArrayWithMunicipiosAndProvincias } from "../../helpers/utils";
import { LocationSearcherBoxItem } from "./LocationSearcherBoxItem";

//Descripción: Caja que aparecerá cuando el resultado escrito coincida con la petición escrita por el cliente
// Cuando el usuario haga clic en una opción, se cargarán los resultados en el ListPrice
// Params:
//inputSearchRef : Referencia al cuadro de texto usado para buscar location
export const LocationSearcherBox = (inputSearchRef) => {
  // Constantes
  //-------
  const dispatch = useDispatch();
  const municipiosState = useSelector((state) => state.municipios);
  const provinciasState = useSelector((state) => state.provincias);
  const uiState = useSelector((state) => state.ui);

  const { municipios } = municipiosState;
  const { provincias } = provinciasState;

  const [title, setTitle] = useState("Buscar municipio o provincia");
  const [municipiosAndProvincias, setMunicipiosAndProvincias] = useState([]);

  // Hooks
  //-------
  useEffect(() => {
    if (municipios.length > 0 || provincias.length > 0) {
      dispatch(uiShowLoadingListBox(true));
      // Crear el array con los municipios y provincias creadas
      setMunicipiosAndProvincias(createArrayWithMunicipiosAndProvincias(municipios, provincias));
    } else {
      // Indicar que no se han encontrado ubicaciones -> Probar con provincia
      if (inputSearchRef.inputSearch.current.value.length >2){
        dispatch(uiShowLoadingListBox(true));
      }else{
        dispatch(uiShowLoadingListBox(false));
      }
      // Borro posibles búsquedas anteriores      
      dispatch(locationsRemoveFoundItems())
      setTitle(`No se han encontrado gasolineras en ${inputSearchRef.inputSearch.current.value}. Inténtalo por provincia`);
    }
  }, [municipios, provincias, inputSearchRef.inputSearch, dispatch]);

  // Controlar cuando cambien los municipios y Provincias según la búsqueda introducida
  useEffect(() => {   
      // Añado los locations al Store (Municipios y Provincias)      
      dispatch( locationAddFoundItems(municipiosAndProvincias) );
      // Añado el título correspondiente
      if (municipiosAndProvincias.length === 0) {
        setTitle("Buscar municipio o provincia");
      } else if (municipiosAndProvincias.length === 1) {
        setTitle(`${municipiosAndProvincias.length} ubicación encontrada con gasolineras`);
      } else if (municipiosAndProvincias.length > 1) {
        setTitle(`${municipiosAndProvincias.length} ubicaciones encontradas con gasolineras`);
      }
  }, [municipiosAndProvincias])

  return (
    <div
      className={`locationSearcherBox__container ${
        uiState.showLocationListBox === true ? "visible" : "not-visible"
      } `}
    >
      <h4>{title}</h4>
      
      {municipiosAndProvincias.map((location, i) => {
        return (
          <LocationSearcherBoxItem
            key={i}
            location={location}
            inputSearchRef={inputSearchRef}
          />
        );
      })}
    </div>
  );
};
