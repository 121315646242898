// Realizar peticiones a backEnd

// URL Base que será el servidor donde esté el backend (BBDD)
const baseURL = process.env.REACT_APP_API;

// Petición sin necesidad de token
const fetchSinToken = (endPoint, data, params = [], method = "GET") => {
   const urlBase = `${baseURL}/${endPoint}`;
    //console.log("URL BASE: " + urlBase);
    let url = new URL(urlBase)
    // Si hay parámetros, los añadirá a la URL
    url.search = new URLSearchParams(params).toString();
    
  if (method === "GET") {
    return fetch(url);
  } else {
    // Devuelveme el resultado de la petición y construyo la fetch indicándole en el header que le mando los datos en json
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchConToken = (endPoint, data, method = "GET") => {
  const url = `${baseURL}/${endPoint}`;
  const token = localStorage.getItem("token") || "";

  if (method === "GET") {
    return fetch(url, {
      headers: {
        "x-token": token,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify(data),
    });
  }
};
export { fetchSinToken, fetchConToken };
