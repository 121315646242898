import { types } from "../types/types";

// Acción para establecer en Redux el loading (spinning)  a true o false de la búsqueda de localidad 
export const uiDisplayLoadingLocation = (isLoading) => {
  if (isLoading === true) {
    return { type: types.uiDisplayLoadingLocation };
  } else {
    return { type: types.uiNotDisplayLoadingLocation };
  }
};

// Acción para establecer en Redux el loading a true o false de la visualización del Box donde aparecen los municipios encontrados (donde aparecen los resultados)
export const uiShowLoadingListBox = (display) => {
  if (display === true) {
    return { type: types.uiShowLoadingListBox };
  } else {
    return { type: types.uiNotShowLoadingListBox };
  }
};


// Acción para establecer en Redux el loading (spinning) a true o false de la busqueda de precios
export const uiDisplayLoadingPrices = (isLoading) => {
  if (isLoading === true) {
    return { type: types.uiDisplayLoadingPrices };
  } else {
    return { type: types.uiNotDisplayLoadingPrices };
  }
};
