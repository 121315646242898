// Métodos o implementación de funcionalidad para la app

export const createArrayWithMunicipiosAndProvincias = (municipios, provincias) => {
    // Array resultado de unir los municipios y provincias
    let newArray = [];
    // Creamos un array a partir de los municipios y las provincias
    newArray = municipios.concat(provincias);

    // Devolvemos los municipios y las provincias
    return newArray;
}