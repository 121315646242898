import { fetchSinToken } from "../helpers/fetch";
import { buildParametersForMunicipioNameRequest } from "../helpers/fetchParametersBuilder";
import { types } from "../types/types";
import { uiDisplayLoadingLocation } from "./ui";

// Petición al servidor - Buscar y descargar localidades del usuario según su búsqueda (Async)
export const startFindMunicipioByName = (municipioToFind) => {

  try {
    return async (dispatch, getState) => {
      // 1- Iniciar loading en UI y construyo la petición con parámetros 
      dispatch(uiDisplayLoadingLocation(true));

      const params = buildParametersForMunicipioNameRequest(municipioToFind);
      
      // 2- Se hace la petición al servidor
      const resp = await fetchSinToken(
        // `estacion/findByIdMunicipio/${idMunicipio}`,null,searchMunicipio
        `municipio/findByName`,null,params
      );

      const body = await resp.json();

      // 3- Cargar los resultados en el Store de municipios encontrados
      const municipios = body.res;
      dispatch(municipioAddFoundItems(municipios));

      // 4- Finalizar el loading en UI
      dispatch(uiDisplayLoadingLocation(false));
    };
  } catch (error) {
    console.log(error);
  }
};

// Cargar los municipios encontrados en el store de redux tras haber realizado la petición (Sync)
const municipioAddFoundItems = (municipios) => {
  return {
    type: types.locationMunicipioAddFoundItems,
    payload: municipios,
  };
};

// Establecer la location seleccionada por el usuario (Al hacer clic en la ubicación deseada del listBox)
export const municipiosRemoveFoundItems = () => { 
  return {
    type: types.locationMunicipioRemoveFounditems
  }
}

