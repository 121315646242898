/* 
Guardar la lista de las estaciones de servicio descargadas con sus respectivos precios 
*/

import { types } from "../types/types";

const initialValues = {
  // Estilo del objeto
  /*
        ideess: Int,
        rotulo: String,
        direccion: String,
        horario: String,
        latitud: String,
        longitud: String,
        cp: String,
        precioGasolina95Actual: Float,
        precioGasolina98Actual: Float,
        precioDieselActual: Float,
        fechaActual: String,
    */

  stationList: [],
};

export const estacionReducer = (state = initialValues, action) => {
  switch (action.type) {
    // Añadir gasolineras y sus precios al Store
    case types.estacionAddFoundItems:
      return { ...state, stationList: action.payload };

    default:
      return state;
  }
};
