import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "../reducers/rootReducer";

//import { rootReducer } from "../reducers/rootReducer";



// Así para ver Redux en Desarrollo
//const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
// Así para ocultar Redux Producción 
const composeEnhancers = compose;


// Añadir window.__redux... para que fincionen las herramientas de desarrollador de chrome, Pestaña "Redux"
export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)));
