/*
Guardar los municipios y provincias encontradas (Array).
*/

import { types } from "../types/types";

const initialValues = {

  locationSelected: {
    location: null,
    isLocationSelectedProvincia: null
  },  
  locations: [],
};

export const locationReducer = (state = initialValues, action) => {
  switch (action.type) {
    // Añadir locations  al Store
    case types.locationAddFoundItems:      
      return { ...state, locations: action.payload };
    // Establecer municipio activo al Store (selección del usuario)
    case types.locationSetActive:
      return { ...state, locationSelected: action.payload };
    // Eliminar municipios encontrados del Store. (Nueva posible búsqueda)
    case types.locationRemoveFoundItems:
      return { ...state, locations: [] };
      
    default:
      return state;
  }
};