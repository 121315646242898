import React from "react";
import { SortFilterComboBox } from "./SortFilterComboBox";
import { sortFilterTypes } from "./sortFilterTypes";

// Array de los diferentes Filtros que estarán disponibles.
export const SortFilterData = [
   
  <SortFilterComboBox
    key={sortFilterTypes.sortFilter.carburante}
    comboTitle={sortFilterTypes.comboTitle.carburante}
    sortFilter={sortFilterTypes.sortFilter.carburante}
    panel_data={sortFilterTypes.panelData.carburante}
    default_field_name={sortFilterTypes.sortFilter.carburanteDefaultFieldName}
    items={[
      sortFilterTypes.combustible.gasolina95,
      sortFilterTypes.combustible.gasolina98,
      sortFilterTypes.combustible.diesel,
    ]}
    activeOption={sortFilterTypes.combustible.gasolina95.data_value}
    toolTipMessage={sortFilterTypes.sortFilter.carburanteToolTypeMessage}
  />,
  <SortFilterComboBox
    key={sortFilterTypes.sortFilter.precio}
    comboTitle={sortFilterTypes.comboTitle.precio}
    sortFilter={sortFilterTypes.sortFilter.precio}
    panel_data={sortFilterTypes.panelData.precio}
    default_field_name={sortFilterTypes.sortFilter.precioDefaultFieldName}
    items={[
      sortFilterTypes.precio.precioAscendente,
      sortFilterTypes.precio.precioDescendente,
    ]}
    activeOption={sortFilterTypes.precio.precioAscendente.data_value}
    toolTipMessage={sortFilterTypes.sortFilter.precioToolTypeMessage}
  />,
];
