import { ReactComponent as CarburanteDiesel } from "../ui/img/diesel.svg";
import { ReactComponent as CarburanteGasolina95 } from "../ui/img/gasolina95.svg";
import { ReactComponent as CarburanteGasolina98 } from "../ui/img/gasolina98.svg";

import { ReactComponent as PrecioASC } from "../ui/img/order-precio-menor-icon.svg";
import { ReactComponent as PrecioDESC } from "../ui/img/order-precio-mayor-icon.svg";

// Almacenar aquí las variables de nombres a los posibles combos para que resulte más fácil su mantenimiento
export const sortFilterTypes = {
    // ComboBox-Padre
    //----------------
    comboTitle:{
        carburante: "Combustible",
        precio: "Ordenar por precio",
    },

    //sortFilter: El nombre que tendrá la casilla donde estarán las opciones de filtrado para interacturar con jquery (sortFilter__carburante, sortFilter__price)
    //-----------
    sortFilter: {
        carburante: "sortFilter__carburante",         
        precio: "sortFilter__price",
        carburanteToolTypeMessage: "Ordenar resultados por combustible",
        precioToolTypeMessage: "Ordenar los resultados según su precio",
        carburanteDefaultFieldName:"option-carburante-selected",
        precioDefaultFieldName:"option-price-selected",
    },
    //panel_data: El nombre del panel que contiene los items. Necesario para interacturar con jquery (Ocultar, Mostrar) 
    //-----------
    panelData : {
        carburante: "order-by-carburante",
        precio: "order-by-price",
    },

    //ComboBox-Item - Hijo: Cada uno de los items de los filtros para pasarlo todo de golpe y más sencillo y rápido
    //---------------------

   combustible:{
        gasolina95:{
            data_value:"Gasolina 95",            
            data_option:"gasolina95",
            data_param:"option-carburante-selected",
            icono: CarburanteGasolina95,
            database_field_name: "PrecioGasolina95Actual" // Para ordenar los precios según Gasolina95 
        },
        gasolina98:{
            data_value:"Gasolina 98",            
            data_option:"gasolina98",
            data_param:"option-carburante-selected",
            icono: CarburanteGasolina98,
            database_field_name: "PrecioGasolina98Actual" // Para ordenar los precios según Gasolina98 
        },
        diesel:{
            data_value:"Diesel",            
            data_option:"diesel",
            data_param:"option-carburante-selected",
            icono: CarburanteDiesel,
            database_field_name: "PrecioDieselActual" // Para ordenar los precios según Diesel 
        },
   },

   precio:{
    precioDescendente:{
        data_value:"De mayor a menor",            
        data_option:"DESC", // Se usará para el filtrado en BD
        data_param:"option-price-selected",
        icono: PrecioDESC,        
    },
    precioAscendente:{
        data_value:"De menor a mayor",            
        data_option:"ASC", // Se usará para el filtrado en BD
        data_param:"option-price-selected",
        icono: PrecioASC,
    },
}, 
}