/*
Guardar las provincias encontradas (Array) por el usuario.
*/

import { types } from "../types/types";

const initialValues = {
  provincias: [],
};

export const provinciaReducer = (state = initialValues, action) => {
  switch (action.type) {
    // Añadir provincias encontradas al Store
    case types.locationProvinciaAddFoundItems:
      return { ...state, provincias: action.payload };

    // Eliminar provincias encontradas del Store. (Nueva posible búsqueda)
    case types.locationProvinciaRemoveFounditems:
      return { ...state, provincias: [] };
      
    default:
      return state;
  }
};
