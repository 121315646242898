import { fetchSinToken } from "../helpers/fetch";
import { buildParametersForEstacionPrecioRequest } from "../helpers/fetchParametersBuilder";
import { types } from "../types/types";
import { uiDisplayLoadingPrices } from "./ui";
import {
  dateFormatFromString,
  getCurrentUTC,
  getDateWithHoursAndMinutes,
} from "../helpers/datesFormat";
import { db } from "../firebase/firebaseConfig";

// Petición al servidor - Buscar y descargar las gasolineras según el location pulsado (Municipio o Provincia) seleccionado (Async)
//Params:
// location: Contendrá o la provincia o un municipio (lo pulsado por el usuario)
// isProvincia: Me indicará si se trata de una provincia o de un municipio --> Poder hacer la consulta correspondiente
export const startFindEstacionByLocation = (location, isProvincia) => {
  // Guardaremos en resp la respuesta devuelva por el servidor ya sea estaciones por municipio o por provincia
  let resp = "";

  // Si el location elegido es una provincia -> Coger como ID para buscar el IDProvincia, si no coger el IDMunicipio
  let idLocation = location.IDMunicipio;
  if (isProvincia === true) {
    idLocation = location.IDProvincia;
  }

  try {
    return async (dispatch, getstate) => {
      // 1- Iniciar loading en el UI
      dispatch(uiDisplayLoadingPrices(true));

      // 2- Recojo la ordenación del store Redux para pasarla como parámetros
      const { combustible, precio } = getstate().sortBy;
      const sortByCombustible = combustible.database_field_name;
      const sortPrice = precio.data_option;

      // 3- Se hace la petición al servidor y cargo la respuesta en json      
      const params = buildParametersForEstacionPrecioRequest(
        idLocation,
        sortByCombustible,
        sortPrice,
        null,
        isProvincia
      );

      // 4- Hago petición por Municipio o Provincia
      if (isProvincia === true) {
        resp = await fetchSinToken(`estacion/findByIdProvincia`, null, params);
      } else {
        resp = await fetchSinToken(`estacion/findByIdMunicipio`, null, params);
      }

      // 5- Leemos los datos y mostramos los resultados si hay al menos 1 estación
      const body = await resp.json();
      const estaciones = body.res;

      if (estaciones.length > 0) {
        // 6- Finalizar el loading en UI
        dispatch(uiDisplayLoadingPrices(false));
        // 7- Cargar los resultados en el Store de estaciones encontradas solo si hay alguna nueva
        dispatch(estacionAddFoundItems(estaciones));
      }

      // 8- Añado el municipio seleccionado del usuario a fireBase a modo informativo
      addLocationToFirebase(location, isProvincia);
    };
  } catch (error) {
    console.log(error);
  }
};

// Cargar las estaciones con sus respectivos precios en el store de redux tras haber realizado la petición (Sync)
export const estacionAddFoundItems = (estaciones) => {
  return {
    type: types.estacionAddFoundItems,
    payload: estaciones,
  };
};

// Comprobación de cual es la última actualización que se tiene de los precios
export const startCheckingLastDatePrice = () => {
  try {
    return async (dispatch, getstate) => {
      // Hago la llamada al servidor
      const resp = await fetchSinToken(`estacion/getLastDateCheckPrice`);

      // Recojo los datos devueltos
      const body = await resp.json();
      const lastDateCheckPrice = body.res;

      // Guardo el dato en el Store
      dispatch(uiAddLastDateCheckPrice(lastDateCheckPrice.LastDateCheck));
    };
  } catch (error) {
    console.log(error);
  }
};

// Añadir a Redux simplemente la fecha de última comprobación de precios para que aparezca en el footer de la web y de los precios
const uiAddLastDateCheckPrice = (lastDateCheck) => {
  let lastDate = "Recientemente actualizados";

  if (lastDateCheck != "") {
    //Hay datos
    lastDate = dateFormatFromString(lastDateCheck);
  }
  return {
    type: types.uiAddLastDateCheckPrice,
    payload: lastDate,
  };
};

// Añadir el municipio seleccionado por el usuario a Firebase ( Saber qué buscan los usuarios)
const addLocationToFirebase = async (location, isProvincia) => {
  // Fecha Actual
  const date = getDateWithHoursAndMinutes();

  let newLocationSelectedByUser = {};

  if (isProvincia === true) {
    newLocationSelectedByUser = {
      IDProvincia: location.IDProvincia,      
      Provincia: location.Provincia,      
      Fecha: date,
    };
  } else {
    newLocationSelectedByUser = {
      IDProvincia: location.IDMunicipio,
      IDProvincia: location.IDProvincia,
      Municipio: location.Municipio,
      Provincia: location.Provincia,
      Fecha: date,
    };
    
  }

  // Hago insercción a la BD
  const doc = await db.collection("Busquedas").add(newLocationSelectedByUser);
};
