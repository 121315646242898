
// Enums para facilitar la construcción de los parámetros para iniciar las búsquedas
// Nombres de las columnas de la BD para que corresponda para su ordenación
export const ORDER_BY = {
    GASOLINA_95: "PrecioGasolina95Actual",
    GASOLINA_95_STRING_FORMAT: "PrecioGasolina95Actual", // De momento igual
    GASOLINA_98: "PrecioGasolina98Actual",
    GASOLINA_98_STRING_FORMAT: "PrecioGasolina95Actual", // De momento igual
    DIESEL: "PrecioDieselActual",
    DIESEL_STRING_FORMAT: "PrecioDieselActual", // De momento igual
}

export const ORDER_PRICE = {
    ORDER_ASC : "ASC",
    ORDER_DES: "DESC"
}

// Devuelve un objeto construido para pasárselo a la petición http (fetch)
//Params: 
// orderByCarburante: El carburante que se tendrá en cuenta para ordenar el listado
// orderPrice: Si se desea ordenar de forma ascendente o descendente el precio del carburante
// limit: Si se desea que haya un límite en el resultado de los items devuelvos
export const buildParametersForEstacionPrecioRequest = (idLocation, orderByCarburante = null, orderPrice = null, limit = null, isProvincia) => {

    // Devolver la construcción del parámetro ID si se desean buscar estaciones por Municipio o Provincia
    if (isProvincia === true){
        return {
            ID_PROVINCIA: idLocation,            
            ORDER_BY : orderByCarburante,
            ORDER_PRICE: orderPrice,
            LIMIT: limit
        }   
    }else{
        return {
            ID_MUNICIPIO: idLocation,
            ORDER_BY : orderByCarburante,
            ORDER_PRICE: orderPrice,
            LIMIT: limit
        }
    }
}

// Devuelve un objeto construido (Municipio) para pasárselo a la petición http (fetch) (Consultar )
export const buildParametersForMunicipioNameRequest = (municipioString) => {

    return {
        NAME_MUNICIPIO : municipioString,
    }
}

// Devuelve un objeto construido (Provincia) para pasárselo a la petición http (fetch) (Consultar )
export const buildParametersForProvinciaNameRequest = (provinciaString) => {

    return {
        NAME_PROVINCIA : provinciaString,
    }
}