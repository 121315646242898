import React from "react";
// Componentes Redux
import { Provider } from "react-redux";
import { store } from "./store/store";

// Componentes HTML
import { AppRouter } from "./components/Router/AppRouter";

export const GasonlineApp = () => {
  return (
    <>
      <Provider store={store}>
        
      
        {/* Los componentes se muestran desde AppRouter--> Componente (pages) Home que contiene toda la estructura */}
        <AppRouter>
          
        </AppRouter>



      </Provider>
    </>
  );
};
