import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyA6tK3Bk2EVAFsF-mjvqtzAMg-h6fMfAkc",
  authDomain: "gasonline-web-8c8e5.firebaseapp.com",
  databaseURL: "https://gasonline-web-8c8e5.firebaseio.com",
  projectId: "gasonline-web-8c8e5",
  storageBucket: "gasonline-web-8c8e5.appspot.com",
  messagingSenderId: "85759923641",
  appId: "1:85759923641:web:256aa7a831312cefead268",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Configuración de base de datos de fireBase
const db = firebase.firestore();

// Exporto lo que utilizaré en la app
export { db, firebase };
