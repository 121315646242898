/*
Reducer para establecer elementos de "carga" en la aplicación: 
- El box de búsqueda de localidad del usuario.
- Un spinning cuando se esté buscando una localidad (en el box de búsqueda de localidad).
- Un spinning cuando se haga la petición para descargar los precios de una ubicación seleccionada.
- Un spinning cuando se seleccione el tipo de ordenación.
*/

import { types } from "../types/types";

const initialValues = {
  showLocationListBox: false,
  loadingLocation: false,
  loadingPrices: false,
  loadingSort: false,
  lastDateCheckPrice: "",
};

export const uiReducer = (state = initialValues, action) => {
  switch (action.type) {
    // Mostrar spinning de Cargando búsqueda de municipio
    case types.uiDisplayLoadingLocation:
      return { ...state, loadingLocation: true };
    // Ocultar spinning de Cargando búsqueda de municipio
    case types.uiNotDisplayLoadingLocation:
      return { ...state, loadingLocation: false };
    // Mostrar listBox de resultados de municipios encontrados
    case types.uiShowLoadingListBox:
      return { ...state, showLocationListBox: true };
    // Ocultar listBox de resultados de municipios encontrados
    case types.uiNotShowLoadingListBox:
      return { ...state, showLocationListBox: false };

    // Mostrar spinning de Cargando precios
    case types.uiDisplayLoadingPrices:
      return { ...state, loadingPrices: true };
    // Ocultar spinning de Cargando precios
    case types.uiNotDisplayLoadingPrices:
      return { ...state, loadingPrices: false };

    // Establecer la fecha de última actualización de los precios
    case types.uiAddLastDateCheckPrice:
      console.log(action.payload);
      return { ...state, lastDateCheckPrice: action.payload };

    default:
      return state;
  }
};
