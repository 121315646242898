import React from "react";
import { ContentTitleSubtitleWithImageItem } from "./ContentTitleSubtitleWithImageItem";



// Contenedor de items o información de app con "Título" + "Subtítulo" + "Imágen"
export const ContentBlockTitleSubtitleWithImageBlock = ({ items }) => {

  return (
    <div className="contentBlockTitleSubtitleWithImageBlock">
      {
        items.map( (item, i) => <ContentTitleSubtitleWithImageItem key={i} item={item}/> )
      }
    </div>
  );
};
