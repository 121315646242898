import React from "react";
import { useDispatch } from "react-redux";
import { startFindEstacionByIdMunicipio, startFindEstacionByLocation } from "../../actions/estacion";
import { municipioSetActive } from "../../actions/locationMunicipio";
import { provinciaSetActive } from "../../actions/locationProvincia";
import { locationSetActive } from "../../actions/location";
import { uiShowLoadingListBox } from "../../actions/ui";

// Cada uno de los bloques que aparecerán con las opciones de búsqueda
export const LocationSearcherBoxItem = ({ location, inputSearchRef }) => {
  // Hooks
  //---------
  const dispatch = useDispatch();

  // Constantes
  //--------------

  // Métodos
  //--------------

  // Establecer el item seleccionado como activo para iniciar búsqueda de las estaciones del location seleccionado
  const handleItemSelected = () => {
    // Establecer el location como "seleccionado" - Redux
    // Comprobar si se trata de una provincia o de un municipio    
    if (!isProvincia){
    /////dispatch( municipioSetActive(location) );
    // Accedemos a la referencia del inputSearch y colocamos la búsqueda seleccionada del usuario
      inputSearchRef.inputSearch.current.value = location.Municipio + ", " + location.Provincia;            
    }else{
      ///////dispatch( provinciaSetActive(location) );
      /////// Accedemos a la referencia del inputSearch y colocamos la búsqueda seleccionada del usuario
      inputSearchRef.inputSearch.current.value = location.Provincia + ", " + location.CCAA;
    }
  // Establecemos el location seleccionado por el usuario (selected)
  dispatch( locationSetActive(location, isProvincia) );
  // Iniciar la petición de estaciones de la provincia o municipio seleccionada
  dispatch(startFindEstacionByLocation(location, isProvincia));

    // Ocultamos el box donde aparece el listado de municipios encontrados
    dispatch(uiShowLoadingListBox(false));
  };

  // Comprobar si se trata de un municipio o una provincia
  const isProvincia = !("IDMunicipio" in location);

  return (
    <>
      <div
        className={"locationSearcherBoxItem animate__animated animate__fadeIn " + (isProvincia && "provinciaType" , "municipioType")}
        onClick={handleItemSelected}
      // >{`${municipio.Municipio}, ${municipio.Provincia} `}</div>
      >
      {
        isProvincia ? `${location.Provincia} , ${location.CCAA} ` :  `${location.Municipio}, ${location.Provincia}`
      }  
      
      </div>
    </>
  );
};
