import React from 'react'

//Descripción: Este componente es solo estético, y es el encabezado 
// al buscador de la localidad. 

export const HeaderLocationSearch = () => {
    return (
        <>
        <div className="headerLocationSearch__container">
            <h1 className="headerLocationSearch__title">Encuentra la gasolinera más barata</h1>
            <h2 className="headerLocationSearch__subtitle">Ahorra dinero en cada depósito</h2>
        </div>      
        </>
    );
}
