import React from "react";
import { LocationSearcher } from "../Location/LocationSearcher";
import { FeaturesListBlockItem } from "./FeaturesListBlockItem";

//Descripción: Cuadros descriptivos de las características de la aplicación.
// Esta sección incluye también el formulario para buscar localidad. (LocationSearcher)

export const FeaturesListBlock = () => {
  return (
    <div className="featuresListBlock__main-container">
      <div className="featuresListBlock__container">
        <FeaturesListBlockItem
          title="Actualización de los precios de carburantes en tiempo real"
          description="Los precios de gasolina y diésel de las gasolineras Españolas al momento"
          fontAwesomeClassName="fa fa-euro-sign icon fa-4x"
        />

        <FeaturesListBlockItem
          title="Todas las gasolineras de España, a tu alcance"
          description="Localiza de forma rápida y sencilla tu gasolinera más barata"
          fontAwesomeClassName="fa fa-gas-pump icon fa-4x"
        />

        <FeaturesListBlockItem
          title="Disponible para iPhone y Android"
          fontAwesomeClassName="fa fa-mobile-alt icon fa-4x"
        />
      </div>

      {/* InputBox - Formulario de búsqueda de la localidad /> */}
      <LocationSearcher />
    </div>
  );
};
