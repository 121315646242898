import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Actions
import { startCheckingLastDatePrice } from "../../actions/estacion";


// Páginas-Componentes de la WEB
//-------------------------------
import { ContactPage } from "../../pages/ContactPage";
import { HomePage } from "../../pages/HomePage";
import { PrivacyPage } from "../../pages/PrivacyPage";
import { Footer } from "../ui/Footer/Footer";
import { Navbar } from "../ui/Navbar";

export const AppRouter = () => {

  // Para comprobar última actualización de precios del servidor
  const dispatch = useDispatch()
  // Donde se guardará la fecha de última comprobación
  const {lastDateCheckPrice} = useSelector(state => state.ui)

  // Iniciar llamada comprobación última comprobación de precios
  useEffect(() => {
    dispatch( startCheckingLastDatePrice() )
    
  }, [dispatch]);

  return (
    <Router>      
      <Navbar/>
      <div className="container__global">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/politica-de-privacidad" component={PrivacyPage} />
          <Route exact path="/contacto" component={ContactPage} />
        </Switch>
      </div>
      <Footer/>
    </Router>
  );
};
