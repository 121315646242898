import React from "react";

export const PrivacyPage = () => {
  return (
    <div className="pageMisc__container animate__animated animate__fadeIn">
      <div className="pageMisc__content">
        <h1>Política de privacidad</h1>
        <p>
          El presente Política de Privacidad establece los términos en que
          GasOnlineAPP usa y protege la información que es proporcionada por sus
          usuarios al momento de utilizar su sitio web. Esta compañía está
          comprometida con la seguridad de los datos de sus usuarios. Cuando le
          pedimos llenar los campos de información personal con la cual usted
          pueda ser identificado, lo hacemos asegurando que sólo se empleará de
          acuerdo con los términos de este documento. Sin embargo esta Política
          de Privacidad puede cambiar con el tiempo o ser actualizada por lo que
          le recomendamos y enfatizamos revisar continuamente esta página para
          asegurarse que está de acuerdo con dichos cambios.
        </p>
        <h2>Información que es recogida</h2>
        <p>
          La aplicación GasOnline no recoge ningún tipo de dato de carácter
          personal. La aplicación únicamente utiliza la posición del usuario
          (previa petición explicita) para mostrar las gasolineras cercanas.
        </p>
        <p>
          Por otra parte, este sitio web únicamente recoge información de
          contacto (email y nombre) para poder responder a preguntas realizas a
          través del formulario de contacto de la página web.
        </p>
        <h2>Uso de la información recogida</h2>
        <p>
          El sitio web GasOnlineApp emplea la información con el fin de
          proporcionar el mejor servicio posible. Además de ello, tanto la
          aplicación como la página web han sido diseñadas para cumplir con el
          compromiso de mantener su información segura. Usamos los sistemas más
          avanzados y los actualizamos constantemente para asegurarnos que no
          exista ningún acceso no autorizado.
        </p>

        <h2>Cookies</h2>
        <p>
          Una cookie se refiere a un fichero que es enviado con la finalidad de
          solicitar permiso para almacenarse en su ordenador, al aceptar dicho
          fichero se crea y la cookie sirve entonces para tener información
          respecto al tráfico web, y también facilita las futuras visitas a una
          web recurrente. Otra función que tienen las cookies es que con ellas
          las web pueden reconocerte individualmente y por tanto brindarte el
          mejor servicio personalizado de su web.
        </p>
        <p>
          Nuestro sitio web emplea las cookies para poder identificar las
          páginas que son visitadas y su frecuencia. Esta información es
          empleada únicamente para análisis estadístico y después la información
          se elimina de forma permanente. Usted puede eliminar las cookies en
          cualquier momento desde su ordenador. Sin embargo las cookies ayudan a
          proporcionar un mejor servicio de los sitios web, estás no dan acceso
          a información de su ordenador ni de usted, a menos de que usted así lo
          quiera y la proporcione directamente, visitas a una web.
        </p>
        <p>
          Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría
          de navegadores aceptan cookies automáticamente pues sirve para tener
          un mejor servicio web. También usted puede cambiar la configuración de
          su ordenador para declinar las cookies. Si se declinan es posible que
          no pueda utilizar algunos de nuestros servicios.
        </p>

        <h2>Enlace a terceros</h2>
        <p>
          Este sitio web y la aplicación GasOnline no contienen enlaces a otros
          sitios externos.
        </p>
        <h2>Control de la información personal</h2>
        <p>
          En cualquier momento usted puede restringir la recopilación o el uso
          de la información personal que es proporcionada a nuestro sitio web.
          Esta página web y aplicación no venderá, cederá ni distribuirá la
          información personal que es recopilada sin su consentimiento, salvo
          que sea requerido por un juez con un orden judicial. GasOnlineAPP y
          aplicación se reservan el derecho de cambiar los términos de la
          presente Política de Privacidad en cualquier momento.
        </p>
      </div>
    </div>
  );
};
