import { combineReducers } from "redux";
import { municipioReducer } from "./municipioReducer";
import { provinciaReducer } from "./provinciaReducer";
import { locationReducer } from "./locationReducer";
import { sortReducer } from "./sortReducer";
import { estacionReducer } from "./estacionReducer";
import { uiReducer } from "./uiReducer";



export const rootReducer = combineReducers({
    municipios: municipioReducer,
    provincias: provinciaReducer,
    locations: locationReducer,
    sortBy: sortReducer,
    estaciones: estacionReducer,
    ui: uiReducer,
});
