import React from 'react'

/*Import de media (img)*/
import { ReactComponent as LoadingIcon } from "../ui/img/loading-icon-big.svg";

//Descripción: Pequeño componente de "loading" que vendrá con un título o mensaje
// justo debajo: 
// Ejemplo: LoadingSpinning + "Cargando precios..."

export const LoadingWithMessage = ({message}) => {
    return (
        <div className="animate__animated animate__fadeIn loadingWithMessage__container">
            <LoadingIcon title={message} alt={message}/>
            <p>{message}</p>
        </div>
    )
}
