import React from "react";

/*Import de media (img)*/
import { ReactComponent as DownloadAppButton } from "./img/download-button-icon.svg";
import { ReactComponent as DownloadAndroidAppButton } from "./img/download-button-android-icon.svg";

export const FeaturesListBlockItem = ({
  title,
  description,
  fontAwesomeClassName,
  component,
}) => {
  return (
    <div className="featureBlock__container">
      <div className="featureBlock__feature-img">
        <i className={fontAwesomeClassName} aria-hidden="true"></i>
      </div>
      <div className="featureBlock__feature-title">
        <h2>{title}</h2>
      </div>
      <div className="featureBlock__feature-description">
        <p>
          {description ? (
            description
          ) : (
            <>
              <a href="https://apps.apple.com/es/app/gasonline/id1502291107">
                <DownloadAppButton
                  title="Descargar Gasonline para iPhone"
                  alt="Descargar Gasonline para iPhone"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=es.ead.gasonlineapp">
                <DownloadAndroidAppButton
                  title="Descargar Gasonline para Android"
                  alt="Descargar Gasonline para Android"
                />
              </a>
            </>
          )}
        </p>
      </div>
    </div>
  );
};
