import React from "react";

/*Import de media (img)*/
import { ReactComponent as GasStationLogo } from "./img/gasStation-icon.svg";
const gasStationLogoStyle = {
  padding: "0.56rem",
};

//Descripción: Item de la lista de las gasolineras (Estación y precio)
// Params
// estacion: Será la estación a mostrar con sus precios incluidos
export const ListPriceItem = ({ estacion }) => {
  return (
    <div className="listPriceItem__container animate__animated animate__fadeInUp">
      <div className="listPriceItem__gasStation-info-container">
        <div className="listPriceItem__gasStation-info-withImage-container">
          <GasStationLogo
            title="Estación gasolinera PetroPrix"
            alt="Estación gasolinera PetroPrix"
            style={gasStationLogoStyle}
          />

          <div className="listPriceItem__gasStation-info">
            <h4>{estacion.Rotulo}</h4>
            <p>
              <i className="fa fa-map-signs fa-fw" aria-hidden="true"></i>{" "}
              {estacion.Direccion}
            </p>
            <p>
              <i className="fa fa-clock fa-fw" aria-hidden="true"></i>{estacion.Horario}
            </p>
          </div>
        </div>

        <div className="listPriceItem__gasStation-price-container">
          <div className="listPriceItem__gasStation-price-item">
            <h4>
              Gasolina <span className="gasolina95">95</span>
            </h4>
            <p>
              <span className={ estacion.PrecioGasolina95ActualStringFormat === "No dispone" ? "noPrice" : undefined }>
              {estacion.PrecioGasolina95ActualStringFormat}
              </span>
              {estacion.PrecioGasolina95ActualStringFormat !== "No dispone" && (
                <span className="eLitro"> €/litro</span>
              )}
            </p>
          </div>

          <div className="listPriceItem__gasStation-price-item">
            <h4>
              Gasolina <span className="gasolina98">98</span>
            </h4>
            <p>
            <span className={ estacion.PrecioGasolina98ActualStringFormat === "No dispone" ? "noPrice" : undefined }>
              {estacion.PrecioGasolina98ActualStringFormat}
              </span>
              {estacion.PrecioGasolina98ActualStringFormat !== "No dispone" && (
                <span className="eLitro"> €/litro</span>
              )}
            </p>
          </div>

          <div className="listPriceItem__gasStation-price-item">
            <h4>
              <span className="diesel">Diesel</span>
            </h4>
            <p>
              <span className={ estacion.PrecioDieselActualStringFormat === "No dispone" ? "noPrice" : undefined }>
              {estacion.PrecioDieselActualStringFormat}
              </span>
              {estacion.PrecioDieselActualStringFormat !== "No dispone" && (
                <span className="eLitro"> €/litro</span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
