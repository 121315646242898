/*Import de media (img)*/
// Imágen de GasOnline en iPhone Blanco
import gasOnline_app_preview_image from "../img/iphones-white-image.png";
// Imágen de GasOnline en iPhone Negro
import gasOnline_app_preview_setup_image from "../img/iphones-black-setup-image.png";

// Imagen de Slides de la app GasOnline
import gasOnline_app_slides_preview_image from "../img/gasOnline-app-slides-preview-image.png";
// Imagen de iPhone tumbado con detalles de la app
import gasOnline_app_slides_preview_details_image from "../img/gasOnline-app-slides-preview-details-image.png";



// Info a mostrar en sección "APP Preview"
export const contentBlockItemAppPreview = {
  // Recurso gráfico a mostrar
  image: gasOnline_app_preview_image,
  // Título de la imagen (svg)
  imageTitle:
    "Descarga GasOnline en tu móvil y ahorra dinero en cada respostaje de combustible",
  // Título de la sección
  title: "GasOnline en tu móvil",
  // Párrafos a mostrar
  paragraph1:
    "Encuentra las gasolineras más cercanas también desde tu móvil con GasOnline. GasOnline te ayuda a ahorrar mostrándote los mejores precios de Gasolina 95, Gasolina 98 y Diésel.",
  paragraph2: "Configúrala a tu gusto de forma fácil y rápida.",

  // Cada uno de los bloques de features
  items: [
    {
      description: "Ahorra dinero en combustible con GasOnline",
      fontAwesomeClassName: "fa-piggy-bank",
    },
    {
      description: "Encuentra fácilmente cualquier gasolinera de España",
      fontAwesomeClassName: "fa-map-marked-alt",
    },
    {
      description: "Conoce la evolución de los precios de los carburantes",
      fontAwesomeClassName: "fa-chart-line icon",
    },
  ],
};

// Info a mostrar en sección "APP Preview Ajustes"
export const contentBlockItemAppSetupPreview = {
  // Recurso gráfico a mostrar
  image: gasOnline_app_preview_setup_image,
  // Título de la imagen (svg)
  imageTitle: "Configuración de GasOnline a tu gusto",
  // Título de la sección
  title: "Configuración de GasOnline a a tu medida",
  // Párrafos a mostrar
  paragraph1:
    "GasOnline te permite personalizar la app para facilitar tus búsquedas. Elige el combustible que desees y GasOnline te mostrará los precios actuales de las gasolineras más cercanas.",
  paragraph2:
    "Con GasOnline ahorras con el combustible más barato de tu zona.",

  // Cada uno de los bloques de features
  items: [
    {
      description: "Ahorra en cada depósito de carburante",
      fontAwesomeClassName: "fa-tint",
    },
    {
      description: "Precio de las gasolineras según tu ubicación en cada momento",
      fontAwesomeClassName: "fa-street-view",
    },
    {
      description:
        "Configuración personalizada de GasOnline para un uso sencillo e intuitivo",
      fontAwesomeClassName: "fa-cogs",
    },
  ],
};

// Info a mostrar en sección "APP Preview Ajustes"
export const contentBlockItemAppSlidesPreview = [
  {
    // Recurso gráfico a mostrar
    image: gasOnline_app_slides_preview_image,
    // Título de la imagen
    imageTitle: "El carburante más económico, con GasOnline",
    // Título de la sección
    title: "El carburante más económico, con GasOnline",
    // Párrafos a mostrar
    subtitle:
      "Todas las gasolineras de España y sus precios actualizados en tiempo real en la palma de tu mano, sólo con GasOnline",
  },

  {
    // Recurso gráfico a mostrar
    image: gasOnline_app_slides_preview_details_image,
    // Título de la imagen
    imageTitle:
      "Toda la información de las gasolineras en España con GasOnline",
    // Título de la sección
    title: "Toda la información de las gasolineras en España con GasOnline",
    // Párrafos a mostrar
    subtitle:
      "Dirección, horarios, precios… todo a tu alcance en tu móvil",
  },
];
