import React from "react";
import { ContentBlockFeaturesItem } from "./ContentBlockFeaturesItem";


export const ContentImageWithFeatures = ({ image, imageTitle, title, paragraph1, paragraph2, contentBlockItems, isImageToRight }) => {

  return (
    <div className="contentImageWithFeatures">
      
      
      <div className="contentImageWithFeatures__container">

        <div className={`contentImageWithFeatures__image_box ${ isImageToRight===true && "secondElementOrder" } `}>
        <img src={image} alt={imageTitle} title={imageTitle}/>
        
        </div>
        
        
        <div className="contentImageWithFeatures__content">
          <h3>{title}</h3>
          <p>
          {paragraph1}          
          </p>
          <p>
          {paragraph2}          
          </p>

          <div className={`contentImageWithFeatures__features ${ isImageToRight===true  && "firstElementOrder" }`}>
          {
            contentBlockItems.map( (item, i) => <ContentBlockFeaturesItem key={i} item={item}/> )
          }           
          </div>
        </div>
      </div>
    </div>
  );
};
