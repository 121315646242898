// Acciones relacionadas con locations. Contendrá municipios o provincias encontradas con la búsqueda del usuario.

import { types } from "../types/types";

// Cargar los municipios y provincias encontradas en el store de redux tras haber realizado la petición (Sync)
export const locationAddFoundItems = (locations) => {
    return {
      type: types.locationAddFoundItems,
      payload: locations,
    };
  };
  
  // Establecer la location seleccionada por el usuario (Al hacer clic en la ubicación deseada del listBox)
  export const locationSetActive = (location, isProvincia) => { 
    return {
      type: types.locationSetActive,
      payload: {
          location: location,
          isLocationSelectedProvincia: isProvincia,
      }
    }
  }
  
  // Borrará la location seleccionada por el usuario (Al hacer clic en la ubicación deseada del listBox)
  export const locationsRemoveFoundItems = () => { 
    return {
      type: types.locationRemoveFoundItems
    }
  }