import React from "react";

// Pequeño bloque informativo con icono y descripción.
export const ContentBlockFeaturesItem = ({item}) => {
  return (
    <div className="blockItemWithtitleAndImage__container">
      <i className={`fa ${item.fontAwesomeClassName} icon fa-3x`}></i>
      <p>{item.description}</p>
    </div>
  );
};
