import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "../Pagination/Pagination";
import { ListPriceItem } from "./ListPriceItem";

// Contenedor de cada uno de los ListPriceItem. Los datos los rellenará del state (estaciones.stationList)
export const ListPriceItemList = () => {
  //Hooks
  //-----
  // Listado de estaciones con precio descargadas y almacenadas en el Store de Redux
  const { stationList } = useSelector((state) => state.estaciones);

  // Para poder hacer la paginación de elementos
  //Variables para definir el Pagination (nº de items y la página actual)
  const [currentPage, setCurrentPage] = useState(1);
  const [stationsPerPage, setStationsPerPage] = useState(5);

  // Conocer el índice del último item de la página y el primero
  const indexOfLastStation = currentPage * stationsPerPage;
  const indexOfFirstStation = indexOfLastStation - stationsPerPage;
  // Ahora los items a mostrar en la página
  const currentItems = stationList.slice(
    indexOfFirstStation,
    indexOfLastStation
  );

  // Función que se ejecuta al hacer clic en botón de paginación (Paginate.js)
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  return (
    <div className="listPriceItemList__container">
      {/* {stationList.map((station) =>  */}
      {currentItems.map((station) => (
        // Pintar cada ListPriceItem
        <ListPriceItem key={station.IDEESS} estacion={station} />
      ))}
  
      <Pagination itemsPerPage={stationsPerPage} totalItems={stationList.length} paginate={paginate}/>
    </div>
  );
};
