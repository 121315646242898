import React from "react";
import { useSelector } from "react-redux";
//Description: Contedor para hacer paginación de los resultados obtenidos

export const Pagination = ({ itemsPerPage, totalItems, paginate }) => {

  // Hooks
  //------
  // Cargar la fecha de última comprobación desde Redux
  const {lastDateCheckPrice} = useSelector(state => state.ui);

  // Nº de páginas dependiendo del número de items
  const pageNumbers = [];

  // Conocer el número de "páginas" según el número de items
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Cuando se hace click en elemento de paginación. Se pasará a su padre con "paginate".
  // Cuando se haga click, marcar el item como "active -> css"
  const handlePaginate = (page) => {
    paginate(page);
  };

  return (
    <>
    <nav className="pagination__container">
      {pageNumbers.map((page, i) => (
        <span
          key={i}
          className="paginationItem__page"
          onClick={() => handlePaginate(page)}
        >
          {page}
        </span>
      ))}
    </nav>
    <div className="pagination__footer">
        <p><i className="fa fa-cloud-download-alt icon"></i> Última comprobación de precios: {lastDateCheckPrice}</p>
    </div>
    </>
  );
};
