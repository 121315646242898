import React from "react";

// Componentes cargados
import { ListPrice } from "../components/ListPrice/ListPrice";
import { ContentBlockTitleSubtitleWithImageBlock } from "../components/ui/ContentBlock/ContentBlockTitleSubtitleWithImageBlock";
import {
  contentBlockItemAppPreview,
  contentBlockItemAppSetupPreview,
  contentBlockItemAppSlidesPreview,
} from "../components/ui/ContentBlock/contentData";
import { ContentImageWithFeatures } from "../components/ui/ContentBlock/ContentImageWithFeatures";
import { FeaturesListBlock } from "../components/ui/FeaturesListBlock";
import { HeaderLocationSearch } from "../components/ui/HeaderLocationSearch";

export const HomePage = () => {
  return (
    <div className="animate__animated animate__fadeIn">
      {/* CABECERA DE PÁGINA (Título + Subtítulo) */}
      {/* ------------------------------- */}
      <HeaderLocationSearch />

      {/* BLOQUES DESCRIPTIVOS DE WEB (FEATURES) + BUSCADOR DE PRECIOS */}
      {/* ------------------------------- */}
      <FeaturesListBlock />

      {/* LISTADO DE PRECIOS ENCONTRADOS CON GASOLINERAS */}
      {/* ------------------------------- */}
      <ListPrice />

      {/* BLOQUE DE MÁS INFORMACIÓN DE LA PÁGINA WEB  */}
      {/* -------------------------------------------------- */}
      {/* Bloque info con IMAGEN + Título + Párrafo + Bloque de Features*/}
      <ContentImageWithFeatures
        image={contentBlockItemAppPreview.image}
        imageTitle={contentBlockItemAppPreview.imageTitle}
        title={contentBlockItemAppPreview.title}
        paragraph1={contentBlockItemAppPreview.paragraph1}
        paragraph2={contentBlockItemAppPreview.paragraph2}
        contentBlockItems={contentBlockItemAppPreview.items}
        isImageToRight={false}
      />
      {/* Bloque info con Título + Párrafo + Bloque de Features + IMAGEN */}
      <ContentImageWithFeatures
        image={contentBlockItemAppSetupPreview.image}
        imageTitle={contentBlockItemAppSetupPreview.imageTitle}
        title={contentBlockItemAppSetupPreview.title}
        paragraph1={contentBlockItemAppSetupPreview.paragraph1}
        paragraph2={contentBlockItemAppSetupPreview.paragraph2}
        contentBlockItems={contentBlockItemAppSetupPreview.items}
        isImageToRight={true}
      />

      {/* Bloque info con Título + Subtítulo + IMAGEN */}
      <ContentBlockTitleSubtitleWithImageBlock
        items={contentBlockItemAppSlidesPreview}
      />
    </div>
  );
};
