import React from "react";

/*Import de media (img)*/
import { ReactComponent as MapLogo } from "../ui/img/mapa-icon.svg";

//Descripción: Mensaje informativo para que se inicie una búsqueda cuando no haya location seleccionada.
//Params
//title: Título del panel
//description: Descripción breve de lo que hacer
export const ListPriceItemEmpty = ({ title, description }) => {
  return (
    <div className="listPriceItemEmpty__container">
      <MapLogo
        className="listPriceItemEmpty__container-map-icon"
        title="Buscar localidad"
        alt="Buscar localidad"
      />
      <div className="listPriceItemEmpty__container-info">
        <h2 className="listPriceItemEmpty__container-title">{title}</h2>
        <p className="listPriceItemEmpty__container-description">
          {description}
        </p>
      </div>
    </div>
  );
};
