import { fetchSinToken } from "../helpers/fetch";
import { buildParametersForProvinciaNameRequest } from "../helpers/fetchParametersBuilder";
import { types } from "../types/types";
import { uiDisplayLoadingLocation } from "./ui";

// Petición al servidor - Buscar y descargar provincias según su búsqueda (Async)
export const startFindProvinciaByName = (provinciaToFind) => {

  try {
    return async (dispatch, getState) => {
      // 1- Iniciar loading en UI y construyo la petición con parámetros 
      dispatch(uiDisplayLoadingLocation(true));

      const params = buildParametersForProvinciaNameRequest(provinciaToFind);
      
      // 2- Se hace la petición al servidor
      const resp = await fetchSinToken(
        // `estacion/findByIdMunicipio/${idMunicipio}`,null,searchMunicipio
        `provincia/findByName`,null,params
      );

      const body = await resp.json();

      // 3- Cargar los resultados en el Store de municipios encontrados
      const provincias = body.res;
      dispatch(provinciaAddFoundItems(provincias));

      // 4- Finalizar el loading en UI
      ///////////dispatch(uiDisplayLoadingLocation(false));
    };
  } catch (error) {
    console.log(error);
  }
};

// Cargar las provincias encontradas en el store de redux tras haber realizado la petición (Sync)
const provinciaAddFoundItems = (provincias) => {
  return {
    type: types.locationProvinciaAddFoundItems,
    payload: provincias,
  };
};

// Establecer la location seleccionada por el usuario (Al hacer clic en la ubicación deseada del listBox)
export const provinciasRemoveFoundItems = () => { 
  return {
    type: types.locationProvinciaRemoveFounditems,
  }
}

