// Librerías
//----------
import moment from "moment";
import "moment/locale/es.js"
// Configuración local española
moment.locale("es");

// Convertir fecha string a fecha normal correcta
export const dateFormatFromString = (stringdate) => {
    // Construyo objeto Date y lo convierto a moment
    const date = new Date(stringdate);
    const dateFormattedString = moment(date).format("LLLL");
    
    return dateFormattedString;
}

// Obtener una fecha normal a partir de UTC
export const getDateWithHoursAndMinutes = () => {
    // Construyo objeto Date y lo convierto a moment
    var date = moment.utc(Date().utc).format('DD-MM-YYYY HH:mm:ss');    
    return date; 
}

// Devolver el UTC de la fecha actual
export const getCurrentUTC = () => {
    const utc = new Date().getTime();
    return utc;
};
